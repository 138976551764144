import React from "react";

import { Seo } from "../components/Seo";
import { Header, Footer } from "../components/layout/index";
import { BannerImage } from "../components/atoms/BannerImage";

const Privacy = () => (
  <>
    <Seo title="Protection des données personnelles" />
    <Header />
    <div className="bg-gray-50">
      <div className="max-w-7xl mx-auto py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
        <h2 className="text-3xl font-extrabold text-gray-900 text-center mb-12">
          Politique de protection des données personnelles du site omnidoc.fr
        </h2>

        <a
          className="block my-4 text-action underline font-medium"
          href="/termes/donnees_personnelles.pdf"
          download
        >
          Télécharger
        </a>
        <iframe
          src="/termes/donnees_personnelles.pdf"
          width="100%"
          style={{ height: "800px" }}
        />
      </div>
    </div>
    <BannerImage />
    <Footer />
  </>
);

export default Privacy;
